@import url('https://fonts.googleapis.com/css?family=Cambay:400,700');

@ANQAblack:		rgb(0, 0, 0);		//	#000000;
@ANQAyellow:	rgb(255, 216, 0);	//	#ffd800;
@warningRed:	rgb(220, 50, 50);	//	#dc3232;
@themeFont: 'Cambay', sans-serif;

body {
	font-family: 'Cambay', sans-serif;
	background-color: rgb(255, 255, 255) !important
}

input:-webkit-autofill {
    background-color: fade(@ANQAyellow, 30%) !important;
}

#login {
	padding: 4% 0 0;
}

#login {
	width: 320px;

	@media only screen and (min-width: 500px) {
		width: 420px;
	}
}

.login {
	h1 {
		a {
			width: 150px;
			height: 150px;

			background-image: url('../img/logo__ANQA-login.png');
			background-image: url('../img/logo__ANQA-login.svg');
			background-size: 150px;
			background-position: center top;
			background-repeat: no-repeat;

			&:active,
			&:focus {
				outline: 0;
				box-shadow: none;
			}
		}
	}

	.message,
	#login_error {
		background: fade(@ANQAyellow, 20%);

		border-left: 4px solid @ANQAyellow;
		box-shadow: none;

		margin-top: 40px;

		a {
			color: darken(@ANQAyellow, 20%);
		}
	}

	#login_error {
		border-left: 4px solid  #dc3232;
	}

	label {
		color: @ANQAblack;
		font-family: @themeFont;

		&[for=user_pass] {
			display: block;
			margin-bottom: 16px;
		}
	}
	form {
		box-shadow: none;

		// Checkbox remember password styling
		.forgetmenot {
			margin-top: 14px;
			label {
				color: @ANQAblack;
				font-family: @themeFont;
				font-size: 14px;
				font-style: italic;
			}
		}
	}
	form .input,
	input[type=text] {

		color: @ANQAblack;
		font-family: @themeFont;
		font-size: 16px;

		width: 100%;
		height: 44px;
		box-sizing: border-box;

		background: fade(@ANQAyellow, 30%);

		padding: 3% 3% 1% 3%;
		
		border: solid transparent 2px;
		box-shadow: none;
		outline: none;
		
		transition: border 0.3s ease-in-out;

		&:-webkit-autofill {
			background-color: fade(@ANQAyellow, 30%) !important;
		}
        &:focus,
        &:hover {
            outline: none;
            border: solid @ANQAyellow 2px;
        }
	}
	form input[type=checkbox],
	input[type=checkbox] {
		position: relative;

		width: 25px;
		height: 25px;

		background: fade(@ANQAyellow, 30%);
		box-shadow: none;
		border: solid transparent 2px;
		
		transition: border 0.3s ease-in-out;

        &:focus,
        &:hover {
            outline: none;
			border: solid @ANQAyellow 2px;
        }
		&:checked {
            border: solid @ANQAyellow 2px;

			&::before {
				content: "\f147";
				margin: -6px 0 0 -8px;
				color: #000;
				font-size: 35px;
			}
		}
	}
	.submit {
		.button.button-primary.button-large {
			// width: 160px;
			height: 44px;
			// padding: 0 12px;

			font-size: 16px;
			font-family: @themeFont;
			font-weight: 700;
			text-transform: uppercase;
			text-shadow: none;

			background: #ffd800;
			box-shadow: none;
			box-sizing: border-box;

			padding: 10px 14px 6px 14px;

			border: 0;
			border-radius: 0;

			margin: auto 0 auto;
			
			transition: all 0.3s ease-in-out;

			&:hover {
				box-shadow: 0px 5px 10px 0px rgba(163,163,163,1);
			}
		}
	}

	// Remove the Lost Password Link
	p#nav {
		display: none;
	}

	// Remove the “Back to” Link
	p#backtoblog {
		display: none;
	}

	// created by getecom.pl footer
	&__footer {
		padding: 0 0 3% 0;

		margin: 0 auto;


		a {
			display: block;

			width: 173px;
			height: 31px;

			background-image: url('../img/logo__getecom-footer.png');
			background-image: url('../img/logo__getecom-footer.svg');
			background-size: 173px 31px;
			background-position: center top;
			background-repeat: no-repeat;

			margin: 0 auto;

			filter: grayscale(100%);

			transition: filter 0.3s ease-in-out;

			img {
				display: none;
			}
			&:hover {
				filter: grayscale(0%);
			}
		}
	}
}