@import url('https://fonts.googleapis.com/css?family=Cambay:400,700');
body {
  font-family: 'Cambay', sans-serif;
  background-color: #ffffff !important;
}
input:-webkit-autofill {
  background-color: rgba(255, 216, 0, 0.3) !important;
}
#login {
  padding: 4% 0 0;
}
#login {
  width: 320px;
}
@media only screen and (min-width: 500px) {
  #login {
    width: 420px;
  }
}
.login h1 a {
  width: 150px;
  height: 150px;
  background-image: url('../img/logo__ANQA-login.png');
  background-image: url('../img/logo__ANQA-login.svg');
  background-size: 150px;
  background-position: center top;
  background-repeat: no-repeat;
}
.login h1 a:active,
.login h1 a:focus {
  outline: 0;
  box-shadow: none;
}
.login .message,
.login #login_error {
  background: rgba(255, 216, 0, 0.2);
  border-left: 4px solid #ffd800;
  box-shadow: none;
  margin-top: 40px;
}
.login .message a,
.login #login_error a {
  color: #998200;
}
.login #login_error {
  border-left: 4px solid  #dc3232;
}
.login label {
  color: #000000;
  font-family: 'Cambay', sans-serif;
}
.login label[for=user_pass] {
  display: block;
  margin-bottom: 16px;
}
.login form {
  box-shadow: none;
}
.login form .forgetmenot {
  margin-top: 14px;
}
.login form .forgetmenot label {
  color: #000000;
  font-family: 'Cambay', sans-serif;
  font-size: 14px;
  font-style: italic;
}
.login form .input,
.login input[type=text] {
  color: #000000;
  font-family: 'Cambay', sans-serif;
  font-size: 16px;
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  background: rgba(255, 216, 0, 0.3);
  padding: 3% 3% 1% 3%;
  border: solid transparent 2px;
  box-shadow: none;
  outline: none;
  transition: border 0.3s ease-in-out;
}
.login form .input:-webkit-autofill,
.login input[type=text]:-webkit-autofill {
  background-color: rgba(255, 216, 0, 0.3) !important;
}
.login form .input:focus,
.login input[type=text]:focus,
.login form .input:hover,
.login input[type=text]:hover {
  outline: none;
  border: solid #ffd800 2px;
}
.login form input[type=checkbox],
.login input[type=checkbox] {
  position: relative;
  width: 25px;
  height: 25px;
  background: rgba(255, 216, 0, 0.3);
  box-shadow: none;
  border: solid transparent 2px;
  transition: border 0.3s ease-in-out;
}
.login form input[type=checkbox]:focus,
.login input[type=checkbox]:focus,
.login form input[type=checkbox]:hover,
.login input[type=checkbox]:hover {
  outline: none;
  border: solid #ffd800 2px;
}
.login form input[type=checkbox]:checked,
.login input[type=checkbox]:checked {
  border: solid #ffd800 2px;
}
.login form input[type=checkbox]:checked::before,
.login input[type=checkbox]:checked::before {
  content: "\f147";
  margin: -6px 0 0 -8px;
  color: #000;
  font-size: 35px;
}
.login .submit .button.button-primary.button-large {
  height: 44px;
  font-size: 16px;
  font-family: 'Cambay', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: none;
  background: #ffd800;
  box-shadow: none;
  box-sizing: border-box;
  padding: 10px 14px 6px 14px;
  border: 0;
  border-radius: 0;
  margin: auto 0 auto;
  transition: all 0.3s ease-in-out;
}
.login .submit .button.button-primary.button-large:hover {
  box-shadow: 0px 5px 10px 0px #a3a3a3;
}
.login p#nav {
  display: none;
}
.login p#backtoblog {
  display: none;
}
.login__footer {
  padding: 0 0 3% 0;
  margin: 0 auto;
}
.login__footer a {
  display: block;
  width: 173px;
  height: 31px;
  background-image: url('../img/logo__getecom-footer.png');
  background-image: url('../img/logo__getecom-footer.svg');
  background-size: 173px 31px;
  background-position: center top;
  background-repeat: no-repeat;
  margin: 0 auto;
  filter: grayscale(100%);
  transition: filter 0.3s ease-in-out;
}
.login__footer a img {
  display: none;
}
.login__footer a:hover {
  filter: grayscale(0%);
}
